export const THUMBNAIL_SIZE = [154, 250]
export const THUMBNAIL_SIZE_LARGE = [240, 400]

export const BORDER_RADIUS = '10px'
export const ICON_SIZE = 40
export const MOBILE_DRAWER_HEIGHT = 110

// Design has been considering updating the default border radius to 4px
// this decision has not been green lit to roll out globally, so for PDP related
// items, this "new" value will be used
export const BORDER_RADIUS_V2 = '4px'
