/**
 *
 */
export const isTruthy = (value: unknown) => {
  if (!value || value === 'false' || value === '0') {
    return false
  }
  return true
}

/**
 *
 */
export const isFalsy = (value: unknown) => {
  if (
    !value ||
    value === '' ||
    (value && value === 'false') ||
    (value && value === '0') ||
    value === 0 ||
    value === false
  ) {
    return true
  }
  return false
}
