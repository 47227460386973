import { lens } from '@dhmk/zustand-lens'

import { createPlayerActions } from './actions'
import { createPlayerState } from './state'
import type { IPlayerState, PlayerActions } from './types'

export const PLAYER_SLICE_KEY = 'player'

export interface IPlayerGlobalStateSlice {
  [PLAYER_SLICE_KEY]: IPlayerState & {
    actions: PlayerActions
  }
}

/**
 * Creates a slice of the global state for the player.
 */
export function createPlayerSlice(): IPlayerGlobalStateSlice {
  return {
    [PLAYER_SLICE_KEY]: lens((set) => ({
      ...createPlayerState(),
      actions: createPlayerActions(set),
    })),
  }
}
