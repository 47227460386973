/**
 * @fileoverview
 * Types used by storage.
 */

import type { CookiesCache } from './classes/CookiesCache'
import type { StorageMemoryCache } from './classes/StorageMemoryCache'

export enum StorageConsentLevel {
  NOOP = 'noop', // Testing purposes only, consent level for non-registered keys
  ESSENTIAL = 'essential',
  FUNCTIONAL = 'functional',
  PERFORMANCE = 'performance',
  TARGETING = 'targeting',
}

/**
 * For testing purposes only. Any string is accepted as a storage key.
 */
export type StorageKeyNoop = string

/**
 * Strictly necessary cookies are classified as cookies that must be present for the website
 * to provide the basic functions of the website. They are essential to be able to access
 * features of the website and could include signing in, adding items to a cart, or e-billing.
 * They are typically essential first-party session cookies, but not all first-party cookies
 * are strictly necessary cookies. They allow for a user to navigate back and forth between
 * pages without losing their previous actions from the same session. Strictly necessary
 * cookies are the only cookies that all cookie laws allow to be exempt from requiring
 * user consent. Since these cookies are necessary for the functionality of a website,
 * website owners do not have to get consent from the user to place strictly necessary
 * cookies on their devices.
 *
 * Source: https://www.cookiepro.com/knowledge/what-are-strictly-necessary-cookies/
 */
export enum StorageKeyEssentialEnum {
  // Microsites
  fw_tv_cookie_consent_accepted = 'acceptedCookieConsentOnFirework', // Cookie consent for Firework microsites and fw.tv
  fw_tv_ssrchnnl = 'fw_tv_ssrchnnl', // Microsites SSR channel
  // Widgets & Player
  fw_ads_spcfgpl = 'fw_ads_spcfgpl', // Ad config for playlist
  fw_ads_spcfgchnl = 'fw_ads_spcfgchnl', // Ad config for channel
  fw_ads_spcfgoauthcid = 'fw_ads_spcfgoauthcid', // Ad config for oauth client id
  fw_consent = 'fw_consent', // User consent level for tracking (essential, non-essential)
  fw_ef_pinned_collapsed = 'fw_ef_pinned_collapsed' /** Embed feed carousel in pinned mode collapsed state */,
  fw_gs = 'fw_gs', // Global Store's persist key
  fw_ply_amnmze = 'fw_ply_amnmze', // Check whether PIP iframe is possible (stored in local storage)
  fw_ply_cemail = 'fw_ply_cemail', // Customer's email for placing an order
  fw_ply_coords = 'fw_ply_coords', // Coordinate of minimized player
  fw_ply_dedupe_ad = 'fw_ply_dedupe_ad', // Interstitial dedupe ads
  fw_ply_ots = 'fw_ply_ots', // Customer's order tokens
  fw_ply_pipexit = 'fw_ply_pipexit', // Set when the user closes the pip, used to remember not to open pip again after reload in pinned mode
  fw_ply_scrll = 'fw_ply_scrll', // The last scrollY position of the PiP iframe before closing
  fw_ply_sft_pip = 'fw_ply_sft_pip', // Session storage key to store Soft PIP player state for later re-creation
  fw_ply_vast = 'fw_ply_vast', // Player's VAST storage
  fw_ply_vclsa = 'fw_ply_vclsa', // Player's VAST @dailymotion/vast-client storage keys
  fw_ply_vctc = 'fw_ply_vctc', // Player's VAST @dailymotion/vast-client storage keys
  fw_ply_vctct = 'fw_ply_vctct', // Player's VAST @dailymotion/vast-client storage keys
  fw_tab_id = 'fw_tab_id', // An id that is unique per tab, and maintains the same value through page reloads.
  fw_vid_int = 'fw_vid_int', // Video interaction status as object with interaction ids as keys
  // OTO project
  fw_otos = 'fw_otos', // One to One Store's persist key
  fw_otocall = 'fw_otocall',
  fw_otoconv = 'fw_otoconv',
}

/**
 * Functionality cookies allow websites to remember the user’s site preferences and
 * choices they make on the site including username, region, and language. This allows
 * the website to provide personalized features like local news stories and weather if
 * you share your location. They are anonymous and don’t track browsing activity across
 * other websites. Functionality cookies can include first party, third party, persistent
 * or session cookies. Similar to strictly necessary cookies, functionality cookies are
 * used to provide services you request. These cookies can remember your preferences to
 * boost the user experience on a website.
 *
 * Source: https://www.cookiepro.com/knowledge/what-are-functionality-cookies/
 */

export enum StorageKeyFunctionalEnum {
  fw_se = 'fw_se', // Session storage key to store session data
  fw_uid = 'fw_uid', // Commerce funnel tracking - A UUID to keep track of a user between sessions. Helps tie together pixel events for user journeys
  fw_chid = 'fw_chid', // Commerce funnel tracking - persisted last channel id
  fw_cchid = 'fw_cchid', // Commerce funnel tracking - persisted last chat channel id (Only applicable to OneToOne)
  fw_dsid = 'fw_dsid', // Commerce funnel tracking - The last showroom id a user has visited
  fw_bmid = 'fw_bmid', // Commerce funnel tracking - The last business membership id of a showroom a user has visited
  fw_asidwa = 'fw_asidwa', // Commerce funnel tracking - The last assistance id that an answer video started playing
}

/**
 * Performance cookies are cookies used specifically for gathering data on how visitors use
 *  a website, which pages of a website are visited most often, or if they get error messages
 * on web pages. These cookies monitor only the performance of the site as the user interacts
 * with it. These cookies don’t collect identifiable information on visitors, which means all
 * the data collected is anonymous and only used to improve the functionality of a website.
 *
 * Website owners can use these cookies to gather statistical data on how their website is
 * performing to make improvements. Some examples of performance cookies are cookies that
 * count page visits, idle time by a user on a page, bounce rates, and load speeds. While
 * performance cookies can be third party cookies, they’re usually first party cookies that
 * are either session or persistent cookies.
 *
 * Generally, these cookies are only used by the website and the data isn’t shared broadly.
 * However, in some cases, third parties can place their cookies on your device through
 * a website you visit to determine the best place for advertisements to be placed on
 * the website based on user behavior.
 *
 * Source: https://www.cookiepro.com/knowledge/what-are-performance-cookies/
 */
export enum StorageKeyPerformanceEnum {
  fw_utm = 'fw_utm', // Commerce funnel tracking - latest UTM parameters coming from a URI search params
  fw_lets = 'fw_lets', // Commerce funnel tracking - last watched engaged timestamp in UTC
  fw_vid = 'fw_vid', // Commerce funnel tracking - last watched video id
  fw_attr_ref = 'fw_attr_ref', // Attribution tracking referrer
  fw_laewats = 'fw_laewats', // Commerce funnel tracking - The last timestamp in UTC that an answer video started playing
  fw_abt = 'fw_abt', // Commerce funnel tracking - list of AB tests the visitor received
  fw_bid = 'fw_bid', // [CS-5465] Persist business ID for all users
}

/**
 * Targeting and advertising cookies are specifically designed to gather information from
 * you on your device to display advertisements to you based on relevant topics that interest
 * you. Advertisers will place these cookies on a website with the website operator’s
 * permission. The information the cookies gather on you can be shared with other advertisers
 * to measure the performance of their advertisements. Additionally, another purpose of targeting
 * and advertising cookies is to build user profiles from visitors to the website to gather
 * statistics on the performance of the advertisements that can carry across many websites.
 *
 * These cookies are almost always third-party, persistent cookies. This means that the cookies
 * can follow the user as they visit other websites. Sometimes, the site with the advertising
 * cookies does not display the advertisements but it can target users with advertisements
 * elsewhere even after they’ve left the site. Examples of targeting and advertising cookies
 * include social media cookies that are placed on sites to track users around the web to
 * provide ads to them on social media platforms.
 *
 * Source: https://www.cookiepro.com/knowledge/what-are-targeting-advertising-cookies/
 */
export enum StorageKeyTargetingEnum {
  placeholder = 'placeholder',
}

export type StorageKey =
  | StorageKeyNoop
  | StorageKeyEssentialEnum
  | StorageKeyFunctionalEnum
  | StorageKeyPerformanceEnum
  | StorageKeyTargetingEnum

export type LocalOrSessionStorageReturnInstanceByKey = Record<
  StorageKeyNoop,
  StorageMemoryCache<StorageKeyNoop>
> &
  Record<
    keyof typeof StorageKeyEssentialEnum,
    StorageMemoryCache<StorageKeyEssentialEnum>
  > &
  Record<
    keyof typeof StorageKeyFunctionalEnum,
    StorageMemoryCache<StorageKeyFunctionalEnum>
  > &
  Record<
    keyof typeof StorageKeyPerformanceEnum,
    StorageMemoryCache<StorageKeyPerformanceEnum>
  > &
  Record<
    keyof typeof StorageKeyTargetingEnum,
    StorageMemoryCache<StorageKeyTargetingEnum>
  >

export type CacheStorageReturnInstanceByKey = Record<
  StorageKeyNoop,
  CookiesCache<StorageKeyNoop>
> &
  Record<
    keyof typeof StorageKeyEssentialEnum,
    CookiesCache<StorageKeyEssentialEnum>
  > &
  Record<
    keyof typeof StorageKeyFunctionalEnum,
    CookiesCache<StorageKeyFunctionalEnum>
  > &
  Record<
    keyof typeof StorageKeyPerformanceEnum,
    CookiesCache<StorageKeyPerformanceEnum>
  > &
  Record<
    keyof typeof StorageKeyTargetingEnum,
    CookiesCache<StorageKeyTargetingEnum>
  >
