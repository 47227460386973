import { PlayerLayout } from '../types'

import type { IPlayerGlobalStateSlice } from './slice'
import { PLAYER_SLICE_KEY } from './slice'

/**
 * @returns Whether any player is in fullscreen mode.
 */
export function hasFullscreenPlayerLayoutSelector(
  state: IPlayerGlobalStateSlice,
): boolean {
  return Object.values(state[PLAYER_SLICE_KEY]?.playersLayouts || {}).some(
    (layout) => layout === PlayerLayout.FULLSCREEN,
  )
}

/**
 *
 */
export function isLivestreamJoinEventTrackedSelector(videoEncodedId?: string) {
  return (state: IPlayerGlobalStateSlice): boolean =>
    videoEncodedId
      ? Boolean(
          state[PLAYER_SLICE_KEY]?.isLivestreamJoinEventTrackedByVideoId?.[
            videoEncodedId
          ],
        )
      : false
}

/**
 * @returns player model actions
 */
export function playerActionsSelector(state: IPlayerGlobalStateSlice) {
  if (!state[PLAYER_SLICE_KEY]) {
    return {}
  }
  return state[PLAYER_SLICE_KEY]?.actions || {} // (CS-3310) For some unknown reason, the slice might be undefined. It does not happen to other slices though :man-shrugging:
}
