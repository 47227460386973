import { logError } from '@core/logging'
import {
  EnumFunnelTrackingCookies,
  setCookieWithFallback,
} from '@core/tracking/funnelTracking'
import { getPageUrl } from '@core/window'

/**
 * Persist the business membership id inside storage.
 * @param bmid Business membership id. If not provided, the function will check
 *     if current url has fw_bmid query param
 */
export function persistBusinessMembershipId(bmid?: string) {
  if (!bmid) {
    const url = getPageUrl()
    try {
      if (!url) {
        return
      }

      // Trying to see if using window.URL can resolve the following sentry error
      // https://firework.sentry.io/issues/4493312242/?referrer=jira_integration
      const urlObject = new window.URL(url)
      bmid = urlObject.searchParams.get('fw_bmid') ?? undefined
    } catch (e) {
      logError(e, {
        captureContext: {
          extra: {
            url,
          },
        },
      })
    }
  }

  if (bmid) {
    setCookieWithFallback(
      EnumFunnelTrackingCookies.FW_BUSINESS_MEMBERSHIP_ID,
      bmid,
    )
  }
}
