import { initReactI18next } from 'react-i18next'

import type { InitOptions } from 'i18next'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpBackend from 'i18next-http-backend'

import merge from '@core/lodash/merge'

declare module 'i18next' {
  interface CustomTypeOptions {
    // Latest version of i18next changed return type of `t` function to `string | null`
    // which conflicts with string only html attributes (title, alt). We need to reconfigure
    // the library to return `string` only.
    // https://www.i18next.com/overview/typescript#argument-of-type-defaulttfuncreturn-is-not-assignable-to-parameter-of-type-xyz
    returnNull: false
  }
}

/**
 * Request function to resolve individual translation files.
 * Resolver is used as a `request` for i18next-http-backend
 *
 * Please note:
 * New translation files needs to be added for proper static
 * analysis and chunk creation.
 */

const importPromise = (key: string): Promise<{ default: unknown }> => {
  switch (key) {
    // Arabic
    case '/locales/ar/chatChannel.json':
      return import('@trans/ar/chatChannel.json')
    case '/locales/ar/next.json':
      return import('@trans/ar/next.json')
    case '/locales/ar/oneToOne.json':
      return import('@trans/ar/oneToOne.json')
    case '/locales/ar/player.json':
      return import('@trans/ar/player.json')
    case '/locales/ar/cta.json':
      return import('@trans/ar/cta.json')
    case '/locales/ar/translation.json':
      return import('@trans/ar/translation.json')

    // Czech
    case '/locales/cs/chatChannel.json':
      return import('@trans/cs/chatChannel.json')
    case '/locales/cs/next.json':
      return import('@trans/cs/next.json')
    case '/locales/cs/oneToOne.json':
      return import('@trans/cs/oneToOne.json')
    case '/locales/cs/player.json':
      return import('@trans/cs/player.json')
    case '/locales/cs/cta.json':
      return import('@trans/cs/cta.json')
    case '/locales/cs/translation.json':
      return import('@trans/cs/translation.json')

    // English (default)
    case '/locales/en/chatChannel.json':
      return import('@trans/en/chatChannel.json')
    case '/locales/en/next.json':
      return import('@trans/en/next.json')
    case '/locales/en/oneToOne.json':
      return import('@trans/en/oneToOne.json')
    case '/locales/en/player.json':
      return import('@trans/en/player.json')
    case '/locales/en/cta.json':
      return import('@trans/en/cta.json')
    case '/locales/en/translation.json':
      return import('@trans/en/translation.json')

    // Spanish
    case '/locales/es/chatChannel.json':
      return import('@trans/es/chatChannel.json')
    case '/locales/es/next.json':
      return import('@trans/es/next.json')
    case '/locales/es/oneToOne.json':
      return import('@trans/es/oneToOne.json')
    case '/locales/es/player.json':
      return import('@trans/es/player.json')
    case '/locales/es/cta.json':
      return import('@trans/es/cta.json')
    case '/locales/es/translation.json':
      return import('@trans/es/translation.json')

    // French
    case '/locales/fr/chatChannel.json':
      return import('@trans/fr/chatChannel.json')
    case '/locales/fr/next.json':
      return import('@trans/fr/next.json')
    case '/locales/fr/oneToOne.json':
      return import('@trans/fr/oneToOne.json')
    case '/locales/fr/player.json':
      return import('@trans/fr/player.json')
    case '/locales/fr/cta.json':
      return import('@trans/fr/cta.json')
    case '/locales/fr/translation.json':
      return import('@trans/fr/translation.json')

    // German
    case '/locales/de/chatChannel.json':
      return import('@trans/de/chatChannel.json')
    case '/locales/de/next.json':
      return import('@trans/de/next.json')
    case '/locales/de/oneToOne.json':
      return import('@trans/de/oneToOne.json')
    case '/locales/de/player.json':
      return import('@trans/de/player.json')
    case '/locales/de/cta.json':
      return import('@trans/de/cta.json')
    case '/locales/de/translation.json':
      return import('@trans/de/translation.json')

    // Hindi
    case '/locales/hi/chatChannel.json':
      return import('@trans/hi-IN/chatChannel.json')
    case '/locales/hi/next.json':
      return import('@trans/hi-IN/next.json')
    case '/locales/hi/oneToOne.json':
      return import('@trans/hi-IN/oneToOne.json')
    case '/locales/hi/player.json':
      return import('@trans/hi-IN/player.json')
    case '/locales/hi/cta.json':
      return import('@trans/hi-IN/cta.json')
    case '/locales/hi/translation.json':
      return import('@trans/hi-IN/translation.json')

    // Indonesian
    case '/locales/id/chatChannel.json':
      return import('@trans/id/chatChannel.json')
    case '/locales/id/next.json':
      return import('@trans/id/next.json')
    case '/locales/id/oneToOne.json':
      return import('@trans/id/oneToOne.json')
    case '/locales/id/player.json':
      return import('@trans/id/player.json')
    case '/locales/id/cta.json':
      return import('@trans/id/cta.json')
    case '/locales/id/translation.json':
      return import('@trans/id/translation.json')

    // Italian
    case '/locales/it/chatChannel.json':
      return import('@trans/it/chatChannel.json')
    case '/locales/it/next.json':
      return import('@trans/it/next.json')
    case '/locales/it/oneToOne.json':
      return import('@trans/it/oneToOne.json')
    case '/locales/it/player.json':
      return import('@trans/it/player.json')
    case '/locales/it/cta.json':
      return import('@trans/it/cta.json')
    case '/locales/it/translation.json':
      return import('@trans/it/translation.json')

    // Japanese
    case '/locales/ja/chatChannel.json':
      return import('@trans/ja/chatChannel.json')
    case '/locales/ja/next.json':
      return import('@trans/ja/next.json')
    case '/locales/ja/oneToOne.json':
      return import('@trans/ja/oneToOne.json')
    case '/locales/ja/player.json':
      return import('@trans/ja/player.json')
    case '/locales/ja/cta.json':
      return import('@trans/ja/cta.json')
    case '/locales/ja/translation.json':
      return import('@trans/ja/translation.json')

    // Dutch (Netherlands)
    case '/locales/nl/chatChannel.json':
      return import('@trans/nl/chatChannel.json')
    case '/locales/nl/next.json':
      return import('@trans/nl/next.json')
    case '/locales/nl/oneToOne.json':
      return import('@trans/nl/oneToOne.json')
    case '/locales/nl/player.json':
      return import('@trans/nl/player.json')
    case '/locales/nl/cta.json':
      return import('@trans/nl/cta.json')
    case '/locales/nl/translation.json':
      return import('@trans/nl/translation.json')

    // Polish
    case '/locales/pl/chatChannel.json':
      return import('@trans/pl/chatChannel.json')
    case '/locales/pl/next.json':
      return import('@trans/pl/next.json')
    case '/locales/pl/oneToOne.json':
      return import('@trans/pl/oneToOne.json')
    case '/locales/pl/player.json':
      return import('@trans/pl/player.json')
    case '/locales/pl/cta.json':
      return import('@trans/pl/cta.json')
    case '/locales/pl/translation.json':
      return import('@trans/pl/translation.json')

    // Portuguese (Brazil)
    case '/locales/pt/chatChannel.json':
      return import('@trans/pt-BR/chatChannel.json')
    case '/locales/pt/next.json':
      return import('@trans/pt-BR/next.json')
    case '/locales/pt/oneToOne.json':
      return import('@trans/pt-BR/oneToOne.json')
    case '/locales/pt/player.json':
      return import('@trans/pt-BR/player.json')
    case '/locales/pt/cta.json':
      return import('@trans/pt-BR/cta.json')
    case '/locales/pt/translation.json':
      return import('@trans/pt-BR/translation.json')

    // Russian
    case '/locales/ru/chatChannel.json':
      return import('@trans/ru/chatChannel.json')
    case '/locales/ru/next.json':
      return import('@trans/ru/next.json')
    case '/locales/ru/oneToOne.json':
      return import('@trans/ru/oneToOne.json')
    case '/locales/ru/player.json':
      return import('@trans/ru/player.json')
    case '/locales/ru/cta.json':
      return import('@trans/ru/cta.json')
    case '/locales/ru/translation.json':
      return import('@trans/ru/translation.json')

    // Slovak
    case '/locales/sk/chatChannel.json':
      return import('@trans/sk/chatChannel.json')
    case '/locales/sk/next.json':
      return import('@trans/sk/next.json')
    case '/locales/sk/oneToOne.json':
      return import('@trans/sk/oneToOne.json')
    case '/locales/sk/player.json':
      return import('@trans/sk/player.json')
    case '/locales/sk/cta.json':
      return import('@trans/sk/cta.json')
    case '/locales/sk/translation.json':
      return import('@trans/sk/translation.json')

    // Serbian
    case '/locales/sr/chatChannel.json':
      return import('@trans/sr/chatChannel.json')
    case '/locales/sr/next.json':
      return import('@trans/sr/next.json')
    case '/locales/sr/oneToOne.json':
      return import('@trans/sr/oneToOne.json')
    case '/locales/sr/player.json':
      return import('@trans/sr/player.json')
    case '/locales/sr/cta.json':
      return import('@trans/sr/cta.json')
    case '/locales/sr/translation.json':
      return import('@trans/sr/translation.json')

    // Swedish
    case '/locales/sv/chatChannel.json':
      return import('@trans/sv/chatChannel.json')
    case '/locales/sv/next.json':
      return import('@trans/sv/next.json')
    case '/locales/sv/oneToOne.json':
      return import('@trans/sv/oneToOne.json')
    case '/locales/sv/player.json':
      return import('@trans/sv/player.json')
    case '/locales/sv/cta.json':
      return import('@trans/sv/cta.json')
    case '/locales/sv/translation.json':
      return import('@trans/sv/translation.json')

    // Chinese Simplified
    case '/locales/zh-CN/chatChannel.json':
      return import('@trans/zh-CN/chatChannel.json')
    case '/locales/zh-CN/next.json':
      return import('@trans/zh-CN/next.json')
    case '/locales/zh-oneToOne/next.json':
      return import('@trans/zh-CN/oneToOne.json')
    case '/locales/zh-CN/player.json':
      return import('@trans/zh-CN/player.json')
    case '/locales/zh-CN/cta.json':
      return import('@trans/zh-CN/cta.json')
    case '/locales/zh-CN/translation.json':
      return import('@trans/zh-CN/translation.json')

    // Chinese Traditional
    case '/locales/zh/chatChannel.json':
      return import('@trans/zh-TW/chatChannel.json')
    case '/locales/zh/next.json':
      return import('@trans/zh-TW/next.json')
    case '/locales/zh/oneToOne.json':
      return import('@trans/zh-TW/oneToOne.json')
    case '/locales/zh/player.json':
      return import('@trans/zh-TW/player.json')
    case '/locales/zh/cta.json':
      return import('@trans/zh-TW/cta.json')
    case '/locales/zh/translation.json':
      return import('@trans/zh-TW/translation.json')

    // Chinese Traditional
    case '/locales/zh-HK/chatChannel.json':
      return import('@trans/zh-HK/chatChannel.json')
    case '/locales/zh-HK/next.json':
      return import('@trans/zh-HK/next.json')
    case '/locales/zh-HK/oneToOne.json':
      return import('@trans/zh-HK/oneToOne.json')
    case '/locales/zh-HK/player.json':
      return import('@trans/zh-HK/player.json')
    case '/locales/zh-HK/cta.json':
      return import('@trans/zh-HK/cta.json')
    case '/locales/zh-HK/translation.json':
      return import('@trans/zh-HK/translation.json')

    // Vietnamese
    case '/locales/vi/chatChannel.json':
      return import('@trans/vi/chatChannel.json')
    case '/locales/vi/next.json':
      return import('@trans/vi/next.json')
    case '/locales/vi/oneToOne.json':
      return import('@trans/vi/oneToOne.json')
    case '/locales/vi/player.json':
      return import('@trans/vi/player.json')
    case '/locales/vi/cta.json':
      return import('@trans/vi/cta.json')
    case '/locales/vi/translation.json':
      return import('@trans/vi/translation.json')

    // Korean
    case '/locales/ko/chatChannel.json':
      return import('@trans/ko/chatChannel.json')
    case '/locales/ko/next.json':
      return import('@trans/ko/next.json')
    case '/locales/ko/oneToOne.json':
      return import('@trans/ko/oneToOne.json')
    case '/locales/ko/player.json':
      return import('@trans/ko/player.json')
    case '/locales/ko/cta.json':
      return import('@trans/ko/cta.json')
    case '/locales/ko/translation.json':
      return import('@trans/ko/translation.json')

    default:
      return Promise.reject(`Missing translation ${key}`)
  }
}

// Default config for all i18next instances
export const config: InitOptions = {
  backend: {
    request: function (
      _options: InitOptions,
      url: string,
      _payload: unknown,
      callback: (
        error: string | null,
        response: {
          status?: number
          data?: unknown
        },
      ) => void,
    ): void {
      importPromise(url)
        .then((module) => {
          callback(null, {
            status: 200,
            data: module.default,
          })
        })
        .catch((error) =>
          callback(error, {
            status: 404,
          }),
        )
    },
  },
  debug: false,
  detection: {
    caches: [],
    order: ['querystring', 'navigator'],
  },
  ns: ['translation'],
  defaultNS: 'translation',
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  react: {
    useSuspense: false,
  },
  keySeparator: ':',
  nsSeparator: ':',
  returnNull: false,
}

// i18next instance factory
/**
 *
 */
export const getInstance = (addConfig: InitOptions = {}): typeof i18next => {
  const instance = i18next.createInstance()

  instance.use(HttpBackend).use(LanguageDetector).use(initReactI18next)

  instance.init(merge({}, config, addConfig))

  return instance
}
