import type { IPlayerState } from './types'

/**
 * Global state for player
 */
export function createPlayerState(): IPlayerState {
  return {
    playersLayouts: {},
    isLivestreamJoinEventTrackedByVideoId: {},
  }
}
