import { logError } from '@core/logging'
import { removeCookie } from '@core/storage'
import { removeItem } from '@core/storage/localStorage'

/**
 *
 */
export function removeCookieAndFallback(key: string) {
  // TODO: Update the domain value with the helper
  // function in (GROW-650) to get the proper domain
  try {
    removeCookie(key, { domain: window?.location?.hostname })
    removeItem(key)
  } catch (error) {
    logError(error)
  }
}
