import type { NamedSet } from 'zustand/middleware/devtools'

import type { PlayerLayout } from '../types'

import type { IPlayerState } from './types'

/**
 * Actions for shared player state
 */
export function createPlayerActions(set: NamedSet<IPlayerState>) {
  return {
    playerLayoutSet: (widgetId: string, playerLayout: PlayerLayout) => {
      set(
        (state) => ({
          playersLayouts: { ...state.playersLayouts, [widgetId]: playerLayout },
        }),
        false,
        'playerLayoutSet',
      )
    },
    playerLayoutUnset: (widgetId: string) => {
      set(
        (state) => {
          const { [widgetId]: _, ...playersLayouts } =
            state.playersLayouts || {}
          return { playersLayouts }
        },
        false,
        'playerLayoutUnset',
      )
    },
    setLivestreamJoinEventTracked: (videoId: string) => {
      set(
        (state) => {
          return {
            isLivestreamJoinEventTrackedByVideoId: {
              ...state.isLivestreamJoinEventTrackedByVideoId,
              [videoId]: true,
            },
          }
        },
        false,
        'setLivestreamJoinEventTracked',
      )
    },
    unsetLivestreamJoinEventTracked: (videoId: string) => {
      set(
        (state) => {
          return {
            isLivestreamJoinEventTrackedByVideoId: {
              ...state.isLivestreamJoinEventTrackedByVideoId,
              [videoId]: false,
            },
          }
        },
        false,
        'unsetLivestreamJoinEventTracked',
      )
    },
  }
}
